.hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../images/lounge.jpg') no-repeat center center/cover;
    filter: blur(2px); /* Adjust the blur amount as desired */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 2;
}

.hero .overlay-image {
    background-image: url('../images/background-final.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px; /* Adjust the width of the new image as desired */
    height: 1000px; /* Adjust the height of the new image as desired */
    z-index: -1;
}


.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
    z-index: 10;
}

.hero .content .text-container p{
    margin-top: 10px;
    margin-bottom: 0px;
}

.hero .content .text-container p:first-child {
    font-size: 3rem;
    font-weight: 700;
}

.hero .content .text-container p:nth-child(2) {
    font-size: 1.9rem;
}

.hero .button {
    margin: 1rem 0;
    width: 300px;
    height: 50px;
    font-size: 25px;
    font-weight: 700;
    background-color: #ffea00;
    border: 1px solid #ffea00;
    color: #333;
    cursor: pointer;
}

.hero .button:hover {
    background-color: #e3d002;
    border: 1px solid #e3d002;
}

/* Media query for screens with a maximum width of 600px (mobile devices) */
@media screen and (min-width: 200px) and (max-width: 600px) {
    .hero {
        width: 100vw;
    }

    .text-container{
        margin-bottom: 10px;
    }

    .hero .content .text-container p:first-child {
        font-size: 1.3rem;
        margin-left: 20px;
    }

    .hero .content .text-container p:nth-child(2) {
        font-size: 1.2rem;
    }

    .hero .button {
        width: 130px;
        height: 20px;
        font-size: 1rem;
        margin-left: 30px;
        margin-top: 10px;
    }

    .hero:before {
        background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../images/lonuge-mobile.png') no-repeat center center/cover;
        height: 100%;
        width: 100%;
    }

    .hero .overlay-image {

        transform: translate(-50%, -50%);
        width: 90%;
        z-index: -1;
    }
    
    
}
