.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 50px auto;
}

.galleryWrap .single {
  width: 200px;
  cursor: pointer;
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  .galleryWrap .single {
    width: 150px;
  }
}


.galleryWrap .single img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.galleryWrap .single img:hover {
  transform: scale(1.04);
}

.gallery-container{
  width: 100%;
  height: 80vh;
}

.gallery-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 800;
  margin-top: 100px;
  margin-bottom: 30px;
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.btnNext,
.btnPrev {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: white;
  z-index: 9999;
  font-size: 30px;
}

.btnClose:hover,
.btnNext:hover,
.btnPre:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}
.fullScreenImage {
  width: calc(100%-40px);
  height: calc(100%-40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}
