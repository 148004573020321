.testimonial_container {
    width: 1200px;
}

.testimonial_card {
    margin-top: 120px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: 3rem;
}


.testimonial_img {
    width: 60px;
    border-radius: 3rem;
}

.section_title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.section_subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 200px) and (max-width: 600px) {
    .testimonial_container {
        width: 100%;
    }
    .testimonial_card {
        margin-top: 0px;
        padding: 1rem 1rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
    }
    .section_title{
        font-size: 30px;
    }
    .section_subtitle {
        font-size: 20px;
    }
}